import React, { useEffect, useState } from "react"
import styles from './BaseList.module.css'
import { axiosInstance } from "../../Helpers/axiosInstance";

const View  = () => {
    const [units, setUnits] = useState([])

      const fetchUnits = async () => {
        try {
          const response = await axiosInstance.get('admin/milunits');
          setUnits(response.data.data);
        } catch (error) {
          console.error("Error fetching units:", error);
        }
      };

      useEffect(() => {fetchUnits()}, [])
    return (
        <div className={styles['View-Correct-main-div']}>
            <h2>
                Հենակետերի աղյուսակ
            </h2>
            <span className={styles['print-btn']} onClick={() => window.print()}>Տպել</span> 
            <div className={styles['units']}>
                {units.length && units.map((el => (
                    <div className={styles['each-unit']} key={el._id}>
                        <div className={styles['unit-number']} title="Զորամաս">Զորամաս - {el.unit_number}</div>
                        {el.unit_bases.length && el.unit_bases.map(item => (
                            <div className={styles['each-base']} key={item.base_id}>
                                <div className={styles['each-number']}>{item.base_number}</div>
                            </div>
                        ))}
                    </div>
                )))}      
            </div>
            
        </div>
    )
}


export default View