import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./menu.module.css";
import { Link, useLocation } from "react-router";

const Menu = ({ menu, style }) => {
    const [choosed, setChoosed] = useState(null);
    const {pathname} = useLocation()

    useEffect(() => {
        if(pathname.includes('profile')){
            setChoosed(null)
        }
    }, [pathname])

    const changeChoosed = (category) => {
        category === choosed ? setChoosed(null) : setChoosed(category);
    };

    return (
        <div className={styles["menu"]} style={style}>
            {menu?.map((el, index) => {
                return (
                    <div key={index}>
                        {!el.undercategory ? (
                            <Link
                                to={el.category}
                                className={`${styles["menu-name"]} ${el.category === choosed ? styles["choosed"] : ""}`}
                                onClick={(e) => changeChoosed(el.category)}
                            >
                                <div>{el.name}</div>
                                {el.undercategory && (
                                    <div>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                )}
                            </Link>
                        ) : (
                            <div
                                to={el.category}
                                className={`${styles["menu-name"]} ${el.category === choosed ? styles["choosed"] : ""}`}
                                onClick={(e) => changeChoosed(el.category)}
                            >
                                <div>{el.name}</div>
                                {el.undercategory && (
                                    <div>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                )}
                            </div>
                        )}
                        {choosed === el.category
                            ? el.undercategory !== null && (
                                  <Menu
                                      menu={el.undercategory}
                                      style={{ marginLeft: "30px" }}
                                  />
                              )
                            : ""}
                    </div>
                );
            })}
        </div>
    );
};

export default Menu;
