import React, { useEffect, useState } from 'react'
import styles from "./corpus.module.css"
import { axiosInstance } from '../../Helpers/axiosInstance'

export default function Corpus() {
    const [corpusName, setCorpusName] = useState("")
    const [corpusNumber, setCorpusNumber] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [corpuses, setCorpuses] = useState([])
    const [change, setChange] = useState(null)

    useEffect(() => {
        (async() => {
            const corpuses = await axiosInstance.get('admin/corpuses')
            if(corpuses.status){
                setCorpuses(corpuses.data.data)
            }
        })()
    }, [])

    useEffect(() => {
        if(corpusNumber) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [corpusName, corpusNumber])

    const submitHandler = async () => {
        const res = await axiosInstance.post('admin/corpus', {corpus_name: corpusName, corpus_number: corpusNumber})
        if(res.status){
            setChange(null)
            setCorpusName("")
            setCorpusNumber("")
            setCorpuses([...corpuses, res.data.data])
        }
    }

    const changeCorpus = async (el) => {
        setChange(el)
        setCorpusName(el.corpus_name)
        setCorpusNumber(el.corpus_number)
    }

    const changeHandler = async () => {
        const res = await axiosInstance.put(`admin/corpus/${change._id}`, {corpus_name: corpusName, corpus_number: corpusNumber})
        if(res.status){
            setChange(null)
            setCorpuses(p => {
                let index = corpuses.findIndex(el => el._id === change._id)
                const newCorpuses = [...corpuses]
                newCorpuses[index] = {...change, corpus_name: corpusName, corpus_number: corpusNumber}
                return newCorpuses
            })
            setCorpusName("")
            setCorpusNumber("")
        }
    }

  return (
    <div className={styles["corpus-main-div"]}>
        <div className={styles["corpus-container"]}>
            <h2>Լրացրեք կորպուսի տվյալները</h2>
            <div>
                <label htmlFor="corpusNumber">Կորպուսի համար</label>
                <input type="text" id="corpusNumber" value={corpusNumber} onChange={(e) => setCorpusNumber(e.target.value)} placeholder='Կորպուսի համար' />
            </div>
            <div>
                <label htmlFor="corpusName">Կորպուսի տեղակայման վայր</label>
                <input type="text" id="corpusName" value={corpusName} onChange={(e) => setCorpusName(e.target.value)} placeholder='Կորպուսի անվանում'/>
            </div>
            <button disabled={disabled} className={`confirm-btn`} onClick={() => change ? changeHandler() : submitHandler()}>{change ? "Փոփոխել" : "Ավելացնել"}</button>
        </div>
        <div className={styles["corpus-list"]}>
            <h2>Կորպուսներ</h2>
            <ol>
                <li className={styles["corpus-list-item"]}>
                    <p>Կորպուսի համար</p>
                    <p>Կորպուսի անվանում</p>
                    <p>Ղեկավարման կոճակներ</p>
                </li>
                {corpuses?.map((el, index) => {
                    return <li key={index} className={styles["corpus-list-item"]}>
                            <p>{el?.corpus_number}</p>
                            <p>{el?.corpus_name}</p>
                            <button className={`btn`} onClick={() => changeCorpus(el)}>Փոփոխել</button>
                        </li>
                })}
            </ol>
        </div>
    </div>
  )
}
