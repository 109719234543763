export const menu = [
   {
      name : "Մարտական Հերթապահություն",
      category: "duty",
      undercategory: [
         {
            name : "Հենակետեր (Դիրքեր)",
            category: "base",
            undercategory : null
         },
         // {
         //    name: "ՄՀ տեղեկաքարտ",
         //    category: 'infocard',
         //    undercategory : [
         //       {
         //          name: "Դիտում",
         //          category: "view_info",
         //          undercategory:null
         //       },
         //       {
         //          name: "Մուտքագրում",
         //          category: "write_info",
         //          undercategory:null
         //       },
         //       {
         //          name: "Խմբագրում",
         //          category: "edit_info",
         //          undercategory:null
         //       },
         //    ]
         // }, 
         
         {
            name: "Գնդակոծում",
            category : "firing",
            undercategory : null
         },

         {
            name: "Պատահար",
            category : "accident",
            undercategory : null
         },

         {
            name: "Ստուգում",
            category : "check",
            undercategory : null,
         },

         {
            name : "Ընդհանուր տեղեկութկուններ",
            category : "general_info",
            undercategory : null
         },

         {
           name : "Հակառակորդ",
           category : "rival",
           undercategory : null
         }
      ]
   },

   {
      name : "Պատահար զորամասում",
      category: "object_accident"
    },

    {
      name: "Ստուգում զորամասում",
      categoty: "object_check"
    },
   {
      name: "Կարգավորումներ",
      category: "addings",
      undercategory : [
         {
            name: "Կորպուս",
            category: "corpus",
            undercategory : null
         },
         {
            name: "Զորամաս",
            category: "mil_unit",
            undercategory : null
         },
         {
            name: "Գումարտակ",
            category: "battalion",
            undercategory : null
         },
         {
            name: "Վաշտ",
            category: "company",
            undercategory : null
         }
      ]
   }




   ]