import './App.css';
import MainPage from './Pages/MainPage';
import State from './Components/HOC/State';
import Router from './Pages/Router';

function App() {
  return (
    <div className="App">
      <State>
        <Router/>
      </State>
    </div>
  );
}

export default App;
