import React, { useState} from "react";
import styles from "./BaseList.module.css";
import Editing from "./Editing.jsx";

const Correct = () => {
  const [selected, setSelected] = useState("Մուտքագրում");

  return (
    <div className={styles["View-Correct-main-div"]}>
      <div className={styles["Correct-Mode-div"]}>
        {/* <div className={styles["Correct-Mode-select"]}>
          <div
            className={`${styles["mode-option"]} ${
              selected === "Մուտքագրում" ? styles["selected"] : ""
            }`}
            onClick={() => setSelected("Մուտքագրում")}
          >
            <button>Մուտքագրում</button>
          </div>
          <div
            className={`${styles["mode-option"]} ${
              selected === "Խմբագրում" ? styles["selected"] : ""
            }`}
            onClick={() => setSelected("Խմբագրում")}
          >
            <button>Խմբագրում</button>
          </div>
          <div
            className={`${styles["mode-option"]} ${
              selected === "Հեռացում" ? styles["selected"] : ""
            }`}
            onClick={() => setSelected("Հեռացում")}
          >
            <button>Հեռացում</button>
          </div>
        </div> */}
      </div>

      {selected && (
        <div className={styles["Selection-window"]}>
          <Editing selectedMode={selected} />
        </div>
      )}
    </div>
  );
};

export default Correct;
