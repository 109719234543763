import { useEffect, useState } from "react";
import {Navigate, useLocation, useNavigate, } from "react-router-dom";
import { axiosInstance } from "../../Helpers/axiosInstance";

export function RequireAuthMain({ children }) {
  const [role, setRole] = useState("")

  const navigate = useNavigate()
  let location = useLocation();

  useEffect(() => {
    (async( ) => {
        const res = await axiosInstance.get(`/auth/user`)
        if(res.data.status){
            setRole(res.data.data.role)
        }else{
            navigate("/login")
        }
    })()
  }, [location.pathname])

  if (!role) { 
   return location.href = "/login"
  }else if(role === "Admin"){
      return children
  }else{
    return <Navigate to="/login" state={{ from: location }} replace />
  }
}


