import { useEffect, useState } from "react";
import styles from "./BaseList.module.css";
import EditBase from "../../Modals/Edit/EditBase.jsx";
import UnitListItem from "../MilUnitList/UnitListItem.jsx";
import BaseListItem from "./BaseListItem.jsx";
import { axiosInstance } from "../../Helpers/axiosInstance.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Editing({ selectedMode }) {
  const [groupingBases, setGroupingBases] = useState([]);
  const [groupingUnits, setGroupingUnits] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBase, setCurrentBase] = useState(null);
  const [isBaseListShown, setIsBaseListShown] = useState(false);
  const [unit, setUnit] = useState(null)
 
  useEffect(() => {
      fetchUnits();
  }, []);

  const onClose= () => {
    setIsModalOpen(false)
    setCurrentBase(null)
  }

  const fetchUnits = async () => {
    try {
      const response = await axiosInstance.get('admin/milunits');
      setGroupingUnits(response.data.data);
      setIsBaseListShown(false);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  
  const fetchBases = async (unit_number) => {
    if (!unit_number) return; 
    try {
      const response = await axiosInstance.get(`/admin/bases-from-unit/${unit_number}`);
      setGroupingBases(response.data.data);
    } catch (error) {
      console.error("Error fetching bases:", error);
    }
  };

  const fetchBase = async (base_number) => {
    if (!base_number) return; 
    try {
      let baseik = base_number
      if(base_number.startsWith("#")){baseik = base_number.replace('#','t')}
      const response = await axiosInstance.get(`/admin/base/${baseik}`);
      setCurrentBase(response.data.data);
    } catch (error) {
      console.error("Error fetching bases:", error);
    }
  };

  return (
    <div className={styles["editing-list-conteiner"]}>
    <div className={styles["editing-list"]}>
      <h3>{!isBaseListShown ? 'Ընտրել զորամասը' : 'Հենակետեր'} {isBaseListShown && <FontAwesomeIcon icon={faPlus} className={styles['adding']} onClick={() => setIsModalOpen(true)} title="Ավելացնել հենակետ"/>}</h3>
      {!isBaseListShown ? 
          groupingUnits.map((item, index) => (
            <UnitListItem
              key={index}
              item={item}
              index={index}
              onClick={() => {fetchBases(item.unit_number); setUnit(item.unit_number); setIsBaseListShown(true); if(selectedMode === "Մուտքագրում"){setCurrentBase(null)}}}
            />
          ))
          :
          isBaseListShown 
          ? groupingBases?.map((item, index) => (
            <BaseListItem
              key={index}
              item={item}
              index={index}
              fetchBase={fetchBase}
              setIsModalOpen={setIsModalOpen}
              setGroupingBases={setGroupingBases}
              unit={unit}
             />
          ))
         
       : (
        <p>Loading data...</p>
      )}
      </div>
      {isModalOpen ? (
        <EditBase 
         onClose={onClose}
         data={currentBase}
         setGroupingBases={setGroupingBases}
         unit={unit}
      />
      ) : ''}
    </div>
  );
}
