import React, { useEffect, useState } from 'react'

export const store = React.createContext()

export default function State({children}) {

  const [baseList, setBaseList]= useState([{name: 'name'}])

  

  const mainState = {
    baseList,
    setBaseList
  }

  return (
    <store.Provider value={mainState}>
        {children}
    </store.Provider>
  )
}