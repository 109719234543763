import React, { useEffect } from "react"
import styles from './MainPage.module.css'
import Menu from "../Components/Menu/Menu.jsx"
import EditMilUnit from "../Modals/Edit/EditMilUnit"
import { menu } from "../Components/Menu/MenuList"
import BaseList from "./BaseList/BaseList.jsx"
import { Outlet, useNavigate } from "react-router"
import Profile from "../Components/Profile.jsx"
const MainPage = () => {

    return (
        <div className={styles['main-div']}>
            <div className={styles['menu-list']}>
                <Profile/>
                <Menu menu={menu} />
            </div>
            <div className={styles['parts']}>
                <Outlet />
            </div>

            {/* <EditMilUnit /> */}
        </div>  
    )
}

export default MainPage