import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../Helpers/axiosInstance'
import styles from './profile.module.css'
import { Link } from 'react-router'

export default function Profile() {
    const [user, setUser] = useState()

      useEffect(() => {
        (async( ) => {
            const res = await axiosInstance.get(`/auth/user`)
            if(res.data.status){
                setUser(res.data.data)
            }
        })()
      }, [])

  return (
    <Link to='profile' className={styles['profile-div']}>
       <img src="/avatar.png" alt='Avatar image'/>
       <p>{user?.username}</p>
    </Link>
  )
}
