import React, { useState } from 'react'
import styles from "../Corpus/corpus.module.css"

export default function ProfileSettings() {
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [fathersName, setFathersName] = useState('')
    const [position, setPosition] = useState('')
    const [rank, setRank] = useState('')
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [repeat, setRepeat] = useState('')

  return (
    <div className={styles["profile-info-container"]}>
        <div className={styles["corpus-container"]}>
            <h3>Անձնական տվյալներ</h3>
            <div>
                <label htmlFor="corpusName">Անուն</label>
                <input type="text" id="corpusName" value={name} onChange={(e) => setName(e.target.value)} placeholder='Անուն'/>
            </div>
            <div>
                <label htmlFor="corpusName">Ազգանուն</label>
                <input type="text" id="corpusName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Ազգանուն'/>
            </div>
            <div>
                <label htmlFor="corpusName">Հայրանուն</label>
                <input type="text" id="corpusName" value={fathersName} onChange={(e) => setFathersName(e.target.value)} placeholder='Հայրանուն'/>
            </div>
            <div>
                <label htmlFor="corpusName">Պաշտոն</label>
                <input type="text" id="corpusName" value={position} onChange={(e) => setPosition(e.target.value)} placeholder='Պաշտոն'/>
            </div>
            <div>
                <label htmlFor="corpusName">Կոչում</label>
                <input type="text" id="corpusName" value={rank} onChange={(e) => setRank(e.target.value)} placeholder='Կոչում'/>
            </div>
        </div>
        <div className={styles["corpus-container"]}>
            <h3>Փոխել գաղտնաբառը</h3>
            <div>
                <label htmlFor="corpusName">Հին գաղտնաբառ</label>
                <input type="password" id="corpusName" value={oldPass} onChange={(e) => setOldPass(e.target.value)} placeholder='Հին գաղտնաբառ'/>
            </div>
            <div>
                <label htmlFor="corpusName">Նոր գաղտնաբառ</label>
                <input type="password" id="corpusName" value={newPass} onChange={(e) => setNewPass(e.target.value)} placeholder='Նոր գաղտնաբառ'/>
            </div>
            <div>
                <label htmlFor="corpusName">Կրկնել գաղտնաբառը</label>
                <input type="password" id="corpusName" value={repeat} onChange={(e) => setRepeat(e.target.value)} placeholder='Կրկնել գաղտնաբառը'/>
            </div>
        </div>
    </div>
  )
}
