import React, { useEffect, useState } from 'react'
import styles from "../Corpus/corpus.module.css"
import { axiosInstance } from '../../Helpers/axiosInstance'

export default function Battalion() {
    const [battalion, setBattalion] = useState("")
    const [battalions, setBatalions] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [milUnits, setMilUnits] = useState([])
    const [change, setChange] = useState(null)
    const [mil, setMil] = useState("")

    useEffect(() => {
        (async() => {
            const milunits = await axiosInstance.get('admin/milunits')
            if(milunits.status){
                setMilUnits(milunits.data.data)
            }
        })()
    }, [])

    useEffect(() => {
           (async() => {
               const batalions = await axiosInstance.get('admin/battalions')
               if(batalions.status){
                setBatalions(batalions.data.data)
               }
           })()
       }, [])

    useEffect(() => {
        if(battalion) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [battalion])

    const submitHandler = async () => {
        const res = await axiosInstance.post('admin/battalion', {battalion_number: battalion, battalion_unit: mil})
        if(res.status){
            setMil("")
            setBattalion("")
            setBatalions([...battalions, res.data.data])
        }
    }

    const changeMilUnit = async (el) => {
        setChange(el)
        setBattalion(el.battalion_number)
        setMil(el.battalion_unit)
    }

    const changeHandler = async () => {
        const res = await axiosInstance.put(`admin/battalion/${change._id}`, {battalion_number: battalion, battalion_unit: mil})
        if(res.status){
            setChange(null)
            setBatalions(p => {
                let index = battalions.findIndex(el => el._id === change._id)
                const newCorpuses = [...battalions]
                newCorpuses[index] = {...change, battalion_number: battalion, battalion_unit : mil}
                return newCorpuses
            })
            setBattalion("")
        }
    }

  return (
    <div className={styles["corpus-main-div"]}>
        <div className={styles["corpus-container"]}>
            <h2>Լրացրեք գումարտակի տվյալները</h2>
             <div>
                <label htmlFor="corpusNumber">Գումարտակի համար/անվանում</label>
                <input type="text" id="corpusNumber" value={battalion} onChange={(e) => setBattalion(e.target.value)} placeholder='Գումարտակի համար/անվանում' />
            </div>
            <div>
                <label htmlFor="unit">Ընտրել զորամասը</label>
                <input list="unit_options" type="text" id="unit" value={mil} onChange={(e) => setMil(e.target.value)} placeholder='Ընտրել զորամասը' />
                <datalist id="unit_options"  onChange={(e) => setMil(e.target.value)} value={mil}>
                    {milUnits.map((el,i) => <option key={i} value={el.unit_number}>{el.unit_number}</option>)}
                </datalist>
            </div>
           
            <button disabled={disabled} className={`confirm-btn`} onClick={() => change ? changeHandler() : submitHandler()}>{change ? "Փոփոխել" : "Ավելացնել"}</button>
        </div>
        <div className={styles["corpus-list"]}>
            <h2>Գումարտակներ</h2>
            <ol>
                <li className={styles["corpus-list-item"]}>
                    <p>Գումարտակի համար</p>
                    <p>Զորամաս</p>
                    <p>Ղեկավարման կոճակներ</p>
                </li>
                {battalions?.map((el, index) => {
                    return <li key={index} className={styles["corpus-list-item"]}>
                            <p>{el?.battalion_number}</p>
                            <p>{el?.battalion_unit}</p>
                            <button className={`btn`} onClick={() => changeMilUnit(el)}>Փոփոխել</button>
                        </li>
                })}
            </ol>
        </div>
    </div>
  )
}
