import React, { useState } from "react"
import styles from './BaseList.module.css'
import View from "./View"
import Correct from "./Correct"
const BaseList = () => {
    const [selected, setSelected] = useState('correct')
    return (
        <div className={styles['BaseList-main-div']}>
            <div className={styles['BaseList-header-div']}>
                <div onClick={() => setSelected('correct')} className={`${styles['BaseList-header']} ${selected === 'correct' ? styles['active-tab'] : ""}`}>
                    <button>Շտկում</button>
                </div>
                <div onClick={() => setSelected('view')} className={`${styles['BaseList-header']} ${selected === 'view' ? styles['active-tab'] : ""}`}>
                    <button>Դիտում</button>
                </div>
            </div>
            <div className={styles['BaseList-body-div']}>
           {selected === 'view' ? <View /> : selected === 'correct' ? <Correct /> : ''  }
            </div>
            
        </div>
    )
}

export default BaseList