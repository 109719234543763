import styles from "./Unit.module.css";

const UnitListItem = ({ item, index, onClick }) => {
  return (
    <div
      className={styles["editing-items"]}
      onClick={onClick}
    >
      {index + 1}. {item.unit_number}
    </div>
  );
};

export default UnitListItem;
