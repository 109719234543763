import React, { useEffect, useState } from 'react'
import styles from "../Corpus/corpus.module.css"
import { axiosInstance } from '../../Helpers/axiosInstance'

export default function MilUnit() {
    const [milName, setMilName] = useState("")
    const [milUnitNumber, setMilUnitNumber] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [milUnits, setMilUnits] = useState([])
    const [change, setChange] = useState(null)
    const [corpuses, setCorpuses] = useState([])
    const [corpus, setCorpus] = useState("")

    useEffect(() => {
        (async() => {
            const milunits = await axiosInstance.get('admin/milunits')
            if(milunits.status){
                setMilUnits(milunits.data.data)
            }
        })()
    }, [])

    useEffect(() => {
           (async() => {
               const corpuses = await axiosInstance.get('admin/corpuses')
               if(corpuses.status){
                   setCorpuses(corpuses.data.data)
               }
           })()
       }, [])

    useEffect(() => {
        if(milUnitNumber) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [milUnitNumber])

    const submitHandler = async () => {
        const res = await axiosInstance.post('admin/milunit', {unit: {unit_number: milUnitNumber, unit_name: milName, unit_corpus: corpus}})
        if(res.status){
            setChange("")
            setMilName("")
            setMilUnitNumber("")
            setCorpus("")
            setMilUnits([...milUnits, res.data.data])
        }
    }

    const changeCorpus = async (el) => {
        setChange(el)
        setMilName(el.unit_name)
        setMilUnitNumber(el.unit_number)
        setCorpus(el.unit_corpus)
    }

    const changeHandler = async () => {
        const res = await axiosInstance.put(`admin/milunit/${change._id}`, {unit_name: milName, unit_number: milUnitNumber, unit_corpus: corpus})
        if(res.status){
            setChange(null)
            setMilUnits(p => {
                let index = milUnits.findIndex(el => el._id === change._id)
                const newCorpuses = [...milUnits]
                newCorpuses[index] = {...change, unit_name: milName, unit_number: milUnitNumber, unit_corpus: corpus}
                return newCorpuses
            })
            setMilName("")
            setMilUnitNumber("")
        }
    }

  return (
    <div className={styles["corpus-main-div"]}>
        <div className={styles["corpus-container"]}>
            <h2>Լրացրեք զորամասի տվյալները</h2>
             <div>
                <label htmlFor="corpusNumber">Զորամասի համար</label>
                <input type="text" id="corpusNumber" value={milUnitNumber} onChange={(e) => setMilUnitNumber(e.target.value)} placeholder='Զորամասի համար' />
            </div>
            <div>
                <label htmlFor="corpusName">Զորամասի անվանում</label>
                <input type="text" id="corpusName" value={milName} onChange={(e) => setMilName(e.target.value)} placeholder='Զորամասի անվանում'/>
            </div>
            <div>
                <label htmlFor="corpus">Ընտրել կորպուսը</label>
                <input list="options" id="corpus" type="text" value={corpus} onChange={(e) => setCorpus(e.target.value)} placeholder='Ընտրել կորպուսը'/>
                <datalist id='options' onChange={(e) => setCorpus(e.target.value)} value={corpus}>
                    {corpuses.map((el,i) => <option key={i} value={el.corpus_number}>{el.corpus_number}</option>)}
                </datalist>
            </div>
           
            <button disabled={disabled} className={`confirm-btn`} onClick={() => change ? changeHandler() : submitHandler()}>{change ? "Փոփոխել" : "Ավելացնել"}</button>
        </div>
        <div className={styles["corpus-list"]}>
            <h2>Զորամասեր</h2>
            <ol>
                <li className={styles["corpus-list-item"]}>
                    <p>Զորամասի համար</p>
                    <p>Զորամասի անվանում</p>
                    <p>Կորպուսի համար</p>
                    <p>Ղեկավարման կոճակներ</p>
                </li>
                {milUnits?.map((el, index) => {
                    return <li key={index} className={styles["corpus-list-item"]}>
                            <p>{el?.unit_number}</p>
                            <p>{el.unit_name}</p>
                            <p>{el.unit_corpus}</p>
                            <button className={`btn`} onClick={() => changeCorpus(el)}>Փոփոխել</button>
                        </li>
                })}
            </ol>
        </div>
    </div>
  )
}
