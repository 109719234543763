import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../MilUnitList/Unit.module.css";
import { faAngleDown, faAngleUp, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../Helpers/axiosInstance";

const BaseListItem = ({ item, index, onClick, fetchBase, setIsModalOpen, setGroupingBases, unit}) => {

  const removeBase = async (base_number) => {
    try {
        let baseik = base_number
        if(base_number.startsWith("#")){baseik = base_number.replace('#','t')}
        const confirm = window.confirm('Վստա՞հ եք, որ ցանկանում եք հեռացնել հենակետը')
        if(confirm){
          const response = await axiosInstance.delete(`admin/base/${baseik}`, {data: {milunit: unit}});
          if(response.status){
            setGroupingBases([...response.data.data])
          }
        }
      } catch (error) {
        console.error("Error fetching bases", )
    }
  }

  const upInTheList = async () => {
      const res = await axiosInstance.put('admin/bases-up', {index, milUnit: unit});
      if(res.data.status){
        setGroupingBases([...res.data.data])
      }
  }

  const downInTheList = async () => {
    const res = await axiosInstance.put('admin/bases-down', {index, milUnit: unit});
    if(res.data.status){
      setGroupingBases([...res.data.data])
    }
}

  return (
    <div
      className={styles["editing-items"]}
      onClick={onClick}
    >
      <div>{index + 1}. {item.base_number}</div>
      <div className={styles["items-controllers"]}>
        <div className={styles["move-items-div"]}>
            <FontAwesomeIcon icon={faAngleUp} className={styles["up"]} title="Բարձրացնել ցանկում" onClick={upInTheList}/>
            <FontAwesomeIcon icon={faAngleDown} className={styles["down"]} title="Իջեցնել ցանկում" onClick={downInTheList}/>
        </div>
        <FontAwesomeIcon icon={faPenToSquare} className={styles["edit"]} title="Խմբագրել" onClick={() => {fetchBase(item.base_number); setIsModalOpen(true)}}/>
        <FontAwesomeIcon icon={faTrash} className={styles["remove"]} title="Ջնջել հենակետը" onClick={() => removeBase(item.base_number)}/>
      </div>
    </div>
  );
};

export default BaseListItem;
