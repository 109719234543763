import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import MainPage from './MainPage'
import Correct from './BaseList/Correct'
import Corpus from './Corpus/Corpus'
import MilUnit from './MilUnit/MilUnit'
import Battalion from './Battalion/Battalion'
import Company from './Company/Company'
import View from './BaseList/View'
import Login from './Login/Login'
import Register from './Login/Register'
import { RequireAuthMain } from './Login/RequireAuth'
import BaseList from './BaseList/BaseList'
import ProfileSettings from './Profile/ProfileSettings'

export default function Router() {
  return (
    <Routes>
        <Route path='/' element={<RequireAuthMain><MainPage/></RequireAuthMain>}>
            <Route index element={<Navigate to='/profile' />} />
            <Route path='profile' element={<ProfileSettings />} />
            <Route path='base' element={<BaseList/>} />
            <Route path='view' element={<View/>} />
            <Route path='edit' element={<Correct/>} />
            <Route path="corpus" element={<Corpus/>} /> 
            <Route path='mil_unit' element={<MilUnit/>} />
            <Route path="battalion" element={<Battalion/>} />
            <Route path="company" element={<Company/>} />
        </Route>
        <Route path='/login' element={<Login/>} />
        <Route path='/reg' element={<Register/>} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
