import React, { useEffect, useState } from 'react'
import styles from "../Corpus/corpus.module.css"
import { axiosInstance } from '../../Helpers/axiosInstance'

export default function Company() {
    const [company, setCompany] = useState("")
    const [companies, setCompanies] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [milUnits, setMilUnits] = useState([])
    const [mil, setMil] = useState("")
    const [battalions,setBattalions] = useState([])
    const [bat, setBat] = useState("")
    const [change, setChange] = useState(null)

    useEffect(() => {
        (async() => {
            const milunits = await axiosInstance.get('admin/milunits')
            if(milunits.status){
                setMilUnits(milunits.data.data)
            }
        })()
    }, [])

    useEffect(() => {
        (async() => {
            const batalions = await axiosInstance.get(`admin/battalions/${mil}`)
            if(batalions.status){
                setBattalions(batalions.data.data)
            }
        })()
        setBat("")
       }, [mil])

       useEffect(() => {
        (async() => {
            const companies = await axiosInstance.get(`admin/companies`)
            if(companies.status){
             setCompanies(companies.data.data)
            }
        })()
    }, [])

    useEffect(() => {
        if(company) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [company])

    const submitHandler = async () => {
        const res = await axiosInstance.post('admin/company', {company_number: company, company_unit: mil, company_battalion: bat})
        if(res.status){
            setMil("")
            setBat("")
            setCompany("")
            setCompanies([...companies, res.data.data])
        }
    }

    const chnageCompany = async (el) => {
        setChange(el)
        setCompany(el.company_number)
        setMil(el.company_unit)
        setBat(el.company_battalion)
    }

    const changeHandler = async () => {
        const res = await axiosInstance.put(`admin/company/${change._id}`, {company_number: company, company_unit: mil, company_battalion: bat})
        if(res.status){
            setChange(null)
            setCompanies(p => {
                let index = companies.findIndex(el => el._id === change._id)
                const newCorpuses = [...companies]
                newCorpuses[index] = {...change, company_number: company, company_unit : mil, company_battalion: bat}
                return newCorpuses
            })
            setCompany("")
        }
    }

  return (
    <div className={styles["corpus-main-div"]}>
        <div className={styles["corpus-container"]}>
            <h2>Լրացրեք վաշտի տվյալները</h2>
             <div>
                <label htmlFor="corpusNumber">Վաշտի համար/անվանում</label>
                <input type="text" id="corpusNumber" value={company} onChange={(e) => setCompany(e.target.value)} placeholder='Վաշտի համար/անվանում' />
            </div>
            <div>
                <label htmlFor="unit">Ընտրել զորամասը</label>
                <input list="unit_options" id="unit" type="text" value={mil} onChange={(e) => setMil(e.target.value)} placeholder='Ընտրել զորամասը'/>
                <datalist id="unit_options" onChange={(e) => setMil(e.target.value)} value={mil}>
                    {milUnits.map((el,i) => <option key={i} value={el.unit_number}>{el.unit_number}</option>)}
                </datalist>
            </div>
            <div>
                <label htmlFor="company">Ընտրել գումարտակը</label>
                <input disabled={battalions.length ? false : true} list="options" id="company" type="text" value={bat} onChange={(e) => setBat(e.target.value)} placeholder='Ընտրել գումարտակը'/>
                <datalist id="options" onChange={(e) => setBat(e.target.value)} value={bat}>
                    {battalions.map((el,i) => <option key={i} value={el.battalion_number}>{el.battalion_number}</option>)}
                </datalist>
            </div>
           
            <button disabled={disabled} className={`confirm-btn`} onClick={() => change ? changeHandler() : submitHandler()}>{change ? "Փոփոխել" : "Ավելացնել"}</button>
        </div>
        <div className={styles["corpus-list"]}>
            <h2>Վաշտեր</h2>
            <ol>
                <li className={styles["corpus-list-item"]}>
                    <p>Վաշտի համար</p>
                    <p>Գումարտակի համար</p>
                    <p>Զորամասի համար</p>
                    <p>Ղեկավարման կոճակներ</p>
                </li>
                {companies?.map((el, index) => {
                    return <li key={index} className={styles["corpus-list-item"]}>
                            <p>{el?.company_number}</p>
                            <p>{el?.company_battalion}</p>
                            <p>{el?.company_unit}</p>
                            <button className={`btn`} onClick={() => chnageCompany(el)}>Փոփոխել</button>
                        </li>
                })}
            </ol>
        </div>
    </div>
  )
}
