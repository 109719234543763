import styles from "./Edit.module.css";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Helpers/axiosInstance";

const EditBase = ({onClose, data , setGroupingBases, unit}) => {
  const [num, setNum] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if(data){
      setNum(data.base_number)
      setName(data.base_name)
    }
  }, [data])

  const handleAddToDatabase = async () => {
    if(num){
      if (num.trim() === "") {
        alert("Please fill in both fields before adding.");
        return;
      }

      try {
        const res = await axiosInstance.post("admin/base", {base: { base_number: num.trim(), base_name: name?.trim(), base_unit: unit }});
        if(res.status){
          setGroupingBases([...res.data.data])
        }
        setNum("");
        setName("");
        onClose(); 
      } catch (error) {
        console.error("Error adding data:", error);
      }
    }
  };

  const handleUpdateDatabase = async () => {
    if (num.trim() === "") {
      alert("Please fill in both fields before updating.");
      return;
    }

    try {
      const base = {
          new_base_number: num,
          base_name: name,
          base_unit: data.base_unit 
      }
      const basik = data.base_number.startsWith("#") ? data.base_number.replace('#','t') : data.base_number
      const res = await axiosInstance.put(`/admin/base/${basik}`, {base: base});
      if(res.status){
        setGroupingBases([...res.data.data])
      }
      onClose(); 
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const getLabelText = () => {
    if (!data) {
      return "Մուտքագրել նոր տվյալներ"
    }else{
      return "Փոփոխել համարը, անվանումը"
    }
  };

  return (
    <div className={styles["editing-container"]} onClick={onClose}>
      <div className={styles["modal"]} onClick={(e) => e.stopPropagation()}>
        <h3>{getLabelText()}</h3>
        {data?.base_number && <h4>Հենակետ {data?.base_number}</h4>}

          <div className={styles["editing-input"]}>
            <input
              className={styles["input-view"]}
              type="text"
              value={num}
              onChange={(e) => setNum(e.target.value)}
              placeholder="Համար"
            />
            <input
              className={styles["input-view"]}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Անվանում"
            />
          </div>
       
          <div className={styles["button-group"]}>
            <button className={`${styles["button-view"]} ${styles["add"]}`} onClick={() => !data ? handleAddToDatabase() : handleUpdateDatabase()}>
              {!data ? 'Ավելացնել' : 'Փոփոխել'}
            </button>
            <button className={`${styles["button-view"]} ${styles["close"]}`} onClick={onClose}>
              Չեղարկել
            </button>
          </div>
      </div>
    </div>
  );
};

export default EditBase;
